<template>
    <div :style="customClass" class="edit-profile-container">
        <!-- Delete current profile component -->
        <div class="wrapSelectProf" style="margin-top: 0px; position: relative">
            <span class="editProfileTitle">Editar nome do perfil</span>
            <input type="text" class="editInputPlaceholder" :placeholder="getProfileName" @focus="fillWithProfileName"
                @input="updateProfileNameEditInfo" v-model="inputValue">
            <div v-if="hasAccess" class="flexWrapIdInfo">
                <span>Id: </span>
                <span>{{ `...${this.currentProfile._id.toString().slice(-7)}` }}</span>
            </div>
            <button v-if="hasAccess" class="excludeProfile" style="background-color: #FF2A2A"
                @click="openDeleteProfileModal()">Excluir
                Perfil</button>
        </div>

        <!-- Change current profile component -->
        <div class="wrapSelectProf">
            <span class="selectProfLabel">Trocar perfil</span>
            <div @click.stop="toggleDropdownList">
                <span class="dropDownPlaceHolder">{{ getProfileName || 'Selecione um perfil' }}</span>
                <img src="@/assets/icons/maps/inputArrow.svg" class="dropDownArrow" />
            </div>

            <ul v-if="dropdownIsOpen" class="dropdownList" @click.stop ref="dropdown">
                <li v-for="(profile, index) in profilesList" :key="String(profile._id)" @click="selectProfile(profile)"
                    :style="{ backgroundColor: index % 2 === 0 ? '#292D32' : '#36393F' }" class="dropDownListItem">
                    {{ profile.name }}
                </li>
            </ul>
        </div>

        <!-- Edit profile name & users -->
        <template>
            <div class="wrapEditNameUsers" v-if="hideUsersList">
                <CustomSearchList mode="users" class="flex-grow" />
            </div>
            <div v-if="!hideUsersList" class="wrapEditBtns">
                <button class="editProfileBtns" style="background-color: #4374EF"
                    @mousedown="openEditProfileNameModal">Salvar</button>
            </div>
        </template>

        <MapModals ref="modal" />
    </div>
</template>

<script>
import MapModals from '../MapModals.vue';
import CustomSearchList from './CustomSearchList.vue';
import MapProfilesControl from '@/sys/Control/MapProfiles/MapProfilesControl';
import BackEnd from '@/sys/BackEnd/BanckEnd';
import { AccessLevel } from '@/sys/Model/Permissions/AccessLevel';

export default {
    components: { MapModals, CustomSearchList },
    name: 'EditProfileInfo',
    props: {
        customClass: Object
    },
    data() {
        return {
            currentProfile: MapProfilesControl.currentProfile || { name: '' },
            profilesList: MapProfilesControl.listOfProfiles || [],
            dropdownIsOpen: false,
            inputValue: "",
            hideUsersList: false,
            hasAccess: BackEnd.getCurrentUserAccess() <= AccessLevel.VENDAS
        }
    },
    created() {
        if (this.hasAccess) {
            this.hideUsersList = true;
        }
        MapProfilesControl.newProfileName = this.currentProfile.name
        MapProfilesControl.addListenerChangedListOfProfile(this.updateProfileList)
    },
    beforeDestroy() {
        MapProfilesControl.removeListenerChangedListOfProfile(this.updateProfileList)
    },
    methods: {
        openDeleteProfileModal() {
            if (this.hasUniqueUsers(this.currentProfile)) {
                this.$refs.modal.showModal(8, `Não é possível excluir o último perfil de um usuário`);
                return
            } else {
                this.$refs.modal.showModal(6, this.currentProfile.name, this.deleteProfile);
            }
        },
        toggleDropdownList() {
            this.dropdownIsOpen = !this.dropdownIsOpen

            if (this.dropdownIsOpen) {
                document.addEventListener("click", this.handleClickOutside);
                document.addEventListener("keydown", this.handleEscPress);
            } else {
                document.removeEventListener("click", this.handleClickOutside);
                document.removeEventListener("keydown", this.handleEscPress);
            }
        },
        updateProfileList() {
            this.profilesList = MapProfilesControl.listOfProfiles;
            this.currentProfile = MapProfilesControl.currentProfile;
            this.inputValue = MapProfilesControl.currentProfile.name
        },
        async selectProfile(profile) {
            this.toggleDropdownList()
            MapProfilesControl.newProfileName = profile.name
            MapProfilesControl.currentMap = undefined;
            MapProfilesControl.setCurrentProfile(profile);
            await MapProfilesControl.setCurrentUserProfile(profile._id);
            MapProfilesControl.callbackChangedListOfProfile();
        },
        handleClickOutside(event) {
            const dropdown = this.$refs.dropdown;
            if (dropdown && !dropdown.contains(event.target)) {
                this.dropdownIsOpen = false;
                document.removeEventListener("click", this.handleClickOutside);
            }
        },
        handleEscPress(event) {
            if (event.key === "Escape") {
                this.dropdownIsOpen = false;
                document.removeEventListener("click", this.handleClickOutside);
                document.removeEventListener("keydown", this.handleEscPress);
            }
        },
        updateProfileNameEditInfo(event) {
            MapProfilesControl.newProfileName = event.target.value;
            this.inputValue = event.target.value;
        },
        async deleteProfile() {
            const data = {
                profileId: MapProfilesControl.currentProfile._id,
            }

            await MapProfilesControl.deleteProfile(data)
        },
        fillWithProfileName() {
            // Fill inputValue with currentProfile name if it's empty
            if (this.inputValue === "") {
                this.inputValue = this.currentProfile.name;
            }
        },
        openEditProfileNameModal() {
            this.$refs.modal.showModal(7, "Deseja confirmar a alteração realizada?", this.editProfileName);
        },
        async editProfileName() {
            this.isLoading = true

            if (MapProfilesControl.newProfileName !== MapProfilesControl.currentProfile.name) {
                const data = {
                    profileId: MapProfilesControl.currentProfile._id,
                    newProfileName: MapProfilesControl.newProfileName,
                    currentProfile: MapProfilesControl.currentProfile
                }

                await MapProfilesControl.updateProfileName(data)
            }
        },
        hasUniqueUsers(currentProfile) {
            // Caso nenhum usuário seja encontrado, return false
            if (!currentProfile.users || currentProfile.users.length === 0) {
                return false;
            }

            // Extraindo ID's do perfil atual e de seus usuários
            const currentProfileId = currentProfile._id;
            const currentProfileUserIds = currentProfile.users.map(user => user._id);

            // Loop de verificação
            for (const userId of currentProfileUserIds) {
                let userIsUnique = true;

                // Checar se o usuário existe em outros perfis
                for (const profile of MapProfilesControl.listOfProfiles) {
                    // Pula o perfil atual
                    if (`${profile._id}` === `${currentProfileId}`) {
                        continue;
                    }

                    const hasUser = profile.users &&
                        profile.users.some(user => `${user._id}` === `${userId}`);

                    if (hasUser) {
                        // Usuário encontrado em outro perfil, ou seja, não é unico
                        userIsUnique = false;
                        break; // Não há necessidade de checar mais para este usuário
                    }
                }

                // Se um usuário único for detectado retorna imediatamente
                if (userIsUnique) {
                    return true;
                }
            }

            // Retorna quando não for encontrado nenhum usuário unico
            return false;
        }
    },
    computed: {
        getProfileName() {
            return this.currentProfile && this.currentProfile.name ? this.currentProfile.name : '';
        }
    },
}
</script>

<style>
.edit-profile-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    /* Take full height of parent */
}

.wrapDelProf {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.currentProf {
    font-family: Arial, Helvetica, sans-serif;
    color: white;
    font-size: 22px;
    opacity: .55;
}

.delProfBtn {
    background-color: #FF2A2A;
    border: none;
    border-radius: 6px;
    font-family: Arial, Helvetica, sans-serif;
    color: white;
    font-size: 11px;
    font-weight: bold;
    padding: 5px 15px;
    cursor: pointer;
}

.wrapSelectProf {
    position: relative;
    width: 100%;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
}

.selectProfLabel {
    font-weight: regular;
    color: #FFFFFF;
    display: block;
    font-family: Arial, Helvetica, sans-serif;
    font-size: .9rem;
    font-weight: bold;
    margin-bottom: 8px;
}

.dropDownPlaceHolder {
    display: block;
    height: 30px;
    border-radius: 6px;
    padding: 8px;
    font-family: Arial, Helvetica, sans-serif;
    color: #78768d;
    font-size: .9rem;
    background-color: #1E2227;
}

.dropDownArrow {
    position: absolute;
    top: 24px;
    right: 0;
    height: 30px;
    cursor: pointer;
}

.dropdownList {
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-top: 50px;
    max-height: 120px;
    width: 100%;
    overflow-y: auto;
    position: absolute;
    z-index: 100;
    border: 2px #4374EF solid;
    border-radius: 0px 0px 6px 6px;
    border-top: none;
}

.dropDownListItem {
    height: 30px;
    padding: 8px;
    font-family: Arial, Helvetica, sans-serif;
    color: #78768d;
    font-size: 12px;
    cursor: pointer;
}

.wrapEditNameUsers {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 0;
    /* Important for flex behavior */
    position: relative;
    width: 100%;
    margin-top: 15px;
}

.editProfileTitle {
    font-weight: regular;
    color: #FFFFFF;
    display: block;
    font-family: Arial, Helvetica, sans-serif;
    font-size: .9rem;
    font-weight: bold;
    margin-bottom: 8px;
}

.editInputPlaceholder {
    display: block;
    height: 30px;
    border-radius: 6px;
    padding: 8px;
    font-family: Arial, Helvetica, sans-serif;
    color: #78768d;
    font-size: .9rem;
    background-color: #1E2227;
    border: none;
    margin-bottom: 10px;
    width: 100%;
}

.editInputPlaceholder:focus {
    outline: none;
}

.excludeProfile {
    position: relative;
    right: 0px;
    border: none;
    border-radius: 6px;
    font-family: Arial, Helvetica, sans-serif;
    color: white;
    font-size: .9rem;
    font-weight: bold;
    padding: 5px 10px;
    cursor: pointer;
    margin-left: auto;
}

.flexWrapIdInfo {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.flexWrapIdInfo span {
    color: rgba(255, 255, 255, .6);
    font-size: .9rem;
    font-family: Arial, Helvetica, sans-serif;
}

@media(max-width: 600px) {
    .editInputPlaceholder {
        margin-bottom: 0px;
    }

    .excludeProfile {
        margin-top: 10px;
    }
}
</style>