<template>
    <div :class="customSearchClasses" v-if="showList">
        <div :class="editInputContainerClass">
            <input type="text" v-model="searchedValue" id="editTextInput" :placeholder="inputPlaceholder"
                class="editNameBarInput" />
            <img src="@/assets/icons/maps/search.svg" alt="Add" class="searchUserIcon" />
        </div>
        <div class="wrapContent">
            <span class="listTitle">
                {{ listTitle }}
            </span>
            <div v-if="isLoading" class="wrapLoaderCustomSearch">
                <span class="loaderCustomSearch"></span>
            </div>
            <div v-else style="padding-top: 15px;">
                <div v-for="(user, index) in filteredList" :key="index" class="wrapRegisteredUsers">
                    <span class="registeredUserName">{{ user.name }}</span>
                    <img v-if="showAddDeleteIcon" class="listIcon"
                        :src="setBtnSrc(user) ? require('@/assets/icons/maps/trashCan.svg') : require('@/assets/icons/maps/add.svg')"
                        :data-icon="setBtnSrc(user) ? 'trash' : 'add'" @click="handleUserIcon(user, $event)">
                </div>
            </div>
        </div>
        <div class="wrapEditBtns" v-if="showBtns">
            <button class="editProfileBtns" style="background-color: #4374EF"
                @mousedown="openEditUserModal">Salvar</button>
        </div>
        <MapModals ref="modal" />
    </div>
</template>

<script>
import BackEnd from '@/sys/BackEnd/BanckEnd';
import MapProfilesControl from '@/sys/Control/MapProfiles/MapProfilesControl';
import { AccessLevel } from '@/sys/Model/Permissions/AccessLevel'
import MapModals from '../MapModals.vue';

export default {
    name: 'CustomSearchList',
    components: { MapModals },
    props: {
        mode: {
            type: String,
            default: "users"
        },
        customClass: {
            type: String,
            default: ""
        },
        profiles: {
            type: Array,
            default: () => []
        },
        allActiveUsers: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            listTitle: '',
            placeHolder: '',
            searchedValue: '',
            currentList: [],
            showAddDeleteIcon: false,
            inputPlaceholder: 'Buscar usuários',
            currentUsersList: [],
            accessLevel: 0,
            showList: false,
            usersToAdd: [],
            isLoading: true,
            showBtns: false
        }
    },
    computed: {
        filteredList() {
            if (!this.searchedValue) return this.currentList

            return this.currentList.filter(item =>
                item.name.toLowerCase().includes(this.searchedValue.toLowerCase())
            )
        },
        editInputContainerClass() {
            return this.mode === "users" ? "editInputContainer usersStyle" : "editInputContainer";
        },
        customSearchClasses() {
            return [
                "wrapCustomSearch", // Static class
                this.customClass,  // Dynamic class
                this.mode === "users" ? "usersWrap" : "", // Conditional class
            ];
        }
    },
    async created() {
        if (this.mode === "users") {
            this.showBtns = true
        }
        this.currentUsersList = MapProfilesControl.currentProfile.users
        const accessLvl = BackEnd.getCurrentUser().customData.accessLevel.$numberInt
        this.accessLevel = accessLvl;
        await this.initializeList()
        MapProfilesControl.addListenerChangedListOfProfile(this.updateCurrentList)
    },
    beforeDestroy() {
        MapProfilesControl.removeListenerChangedListOfProfile(this.updateCurrentList)
    },
    methods: {
        async initializeList() {
            switch (this.mode) {
                case "users":
                    if (this.accessLevel <= AccessLevel.VENDAS) {
                        this.showList = true
                    }
                    this.listTitle = "Usuários"
                    await this.fillUsersList()
                    this.inputPlaceholder = "Buscar usuários"
                    if (BackEnd.getCurrentUserAccess() <= AccessLevel.VENDAS) {
                        this.showAddDeleteIcon = true
                    }
                    this.isLoading = false
                    break
                case "relatedUsers":
                    this.showList = true
                    if (BackEnd.getCurrentUserAccess() <= AccessLevel.VENDAS) {
                        this.showAddDeleteIcon = true
                    }
                    this.listTitle = "Usuários relacionados"
                    this.currentList = MapProfilesControl.currentProfile.users
                    this.inputPlaceholder = "Buscar usuários relacionados"
                    this.isLoading = false
                    break
                case "relatedPlants":
                    this.showList = true
                    this.listTitle = "Mapas relacionados"
                    this.currentList = MapProfilesControl.currentProfile.maps
                    this.inputPlaceholder = "Buscar mapas relacionados"
                    this.isLoading = false
                    break
            }
        },
        async updateCurrentList() {
            this.isLoading = true

            if (this.mode === "relatedUsers") {
                const uniqueArr = this.removeDuplicates(MapProfilesControl.currentProfile.users)
                this.currentList = uniqueArr;
                MapProfilesControl.currentProfile.users = uniqueArr
                this.isLoading = false
            } else if (this.mode === "relatedPlants") {
                this.currentList = MapProfilesControl.currentProfile.maps
                this.isLoading = false
            } else if (this.mode === "users") {
                this.currentUsersList = MapProfilesControl.currentProfile.users
                await this.fillUsersList()
                this.isLoading = false
            }
            this.currentUsersList = MapProfilesControl.currentProfile.users
        },
        setBtnSrc(user) {
            const resp = this.currentUsersList.some(currentUser => `${currentUser._id}` === `${user._id}`)
            return resp
        },
        async fillUsersList() {
            let treatedList = [];

            if (MapProfilesControl.fullUsersList.length === 0) {
                MapProfilesControl.fullUsersList = await MapProfilesControl.myByPass();
            }

            MapProfilesControl.fullUsersList.forEach(obj1 => {
                const match = this.currentUsersList.some(obj2 => `${obj2._id}` === `${obj1._id}`);
                if (!match) {
                    treatedList.push(obj1);
                }
            });

            this.currentList = treatedList;
            MapProfilesControl.usersAvailable = treatedList;
        },
        handleUserIcon(user, event) {
            const imgElement = event.target; // Get the clicked img element
            const currentIcon = imgElement.dataset.icon; // Get the custom data attribute

            if (currentIcon === 'selected') {
                // Change to "add" icon and remove the user from the array
                imgElement.src = require('@/assets/icons/maps/add.svg');
                imgElement.dataset.icon = 'add';

                // Find the user by _id and remove it from the array
                MapProfilesControl.usersToAdd = MapProfilesControl.usersToAdd.filter(existingUser => existingUser._id !== user._id);
            } else if (currentIcon === 'add') {

                if (this.checkHowManyProfiles(user)) {
                    this.$refs.modal.showModal(8, `O limite de perfis deste usuário foi atingido`);
                } else {
                    // Change to "selected" icon and add the user to the array
                    imgElement.src = require('@/assets/icons/maps/selected.svg');
                    imgElement.dataset.icon = 'selected';

                    // Check if the user is already in the array
                    const userExists = MapProfilesControl.usersToAdd.some(existingUser => existingUser._id === user._id);
                    if (!userExists) {
                        MapProfilesControl.usersToAdd.push(user);
                    }
                }
            } else if (currentIcon === 'trash') {
                this.openDeleteUserModal(user);
            }
        },
        openDeleteUserModal(user) {
            this.$refs.modal.showModal(7, `Deseja realmente remover "${user.name}"?`, () => { this.deleteUserFromProfile(user) });
        },
        async deleteUserFromProfile(user) {
            this.isLoading = true;
            if (`${MapProfilesControl.currentProfile._id}` === `${user._id}`) {
                console.error(`Não é possível tirar o usuário atual do perfil`)
                return
            }

            const data = {
                profileId: `${MapProfilesControl.currentProfile._id}`,
                userId: `${user._id}`
            }

            await MapProfilesControl.removeUserFromProfile(data)
        },
        async editProfile() {
            this.isLoading = true

            let usersToAddAux = [];

            if (MapProfilesControl.newProfileName !== MapProfilesControl.currentProfile.name) {
                const data = {
                    profileId: MapProfilesControl.currentProfile._id,
                    newProfileName: MapProfilesControl.newProfileName,
                    currentProfile: MapProfilesControl.currentProfile
                }

                await MapProfilesControl.updateProfileName(data)
            }

            if (MapProfilesControl.usersToAdd.length > 0) {
                await MapProfilesControl.usersToAdd.forEach((user) => {
                    let userToPush = {
                        _id: user._id,
                        name: user.name
                    }

                    usersToAddAux.push(userToPush)
                })

                const data = {
                    profileId: `${MapProfilesControl.currentProfile._id}`,
                    userList: usersToAddAux
                }
                await MapProfilesControl.addUserToProfile(data)
            }

            MapProfilesControl.usersToAdd = []

        },
        openEditUserModal() {
            this.$refs.modal.showModal(7, "Deseja confirmar as alterações realizadas?", this.editProfile);
        },
        removeDuplicates(array) {
            return Object.values(array.reduce((acc, curr) => {
                acc[curr._id] = curr;
                return acc;
            }, {}));
        },
        checkHowManyProfiles(selectedUser) {
            let foundthisMany = 0;

            MapProfilesControl.listOfProfiles.forEach(profile => {
                profile.users.forEach(user => {
                    `${user._id}` === `${selectedUser._id}` && foundthisMany++
                })
            })

            return foundthisMany >= 3;
        }
    }
}
</script>

<style>
.wrapCustomSearch {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.editInputContainer {
    position: relative;
    height: 30px;
    border-radius: 0 6px 6px 0;
}

.editNameBarInput {
    width: 100%;
    padding: 10px;
    padding-right: 40px;
    box-sizing: border-box;
    height: 30px;
    border-radius: 6px;
    padding: 8px;
    font-family: Arial, Helvetica, sans-serif;
    color: #78768d;
    font-size: .9rem;
    background-color: #1E2227;
    border: none;
    outline: none;
}

.searchUserIcon {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    height: 30px;
    width: 30px;
    padding: 7px;
    border-radius: 0 6px 6px 0;
}

.wrapContent {
    width: 100%;
    min-height: 25px;
    max-height: 255px;
    background-color: #1E2227;
    border-radius: 6px;
    margin-top: 5px;
    position: relative;
    overflow-y: auto;
    padding: 5px;
    padding-top: 20px;
    height: fit-content;
}

.usersWrap .wrapContent {
    max-height: none;
}

.listTitle {
    color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1rem;
    position: absolute;
    left: 5px;
    top: 5px;
}

.wrapRegisteredUsers {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 5px;
}

.registeredUserName {
    font-family: Arial, Helvetica, sans-serif;
    color: #78768d;
    font-size: 1.1rem;
}

.fullHeight {
    max-height: calc(100% - 35px);
}

.listIcon {
    cursor: pointer;
}

.usersStyle {
    width: 60%;
}

.usersWrap {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.loaderCustomSearch {
    width: 48px;
    height: 48px;
    border: 5px solid #4374EF;
    border-bottom-color: #1E2227;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}


@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.wrapLoaderCustomSearch {
    width: 100%;
    height: 50px;
    margin-top: 15px;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
}

.wrapEditBtns {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    width: 100%;
}

.editProfileBtns {
    border: none;
    border-radius: 6px;
    font-family: Arial, Helvetica, sans-serif;
    color: white;
    font-size: .9rem;
    font-weight: bold;
    padding: 5px 15px;
    cursor: pointer;
}

@media(max-width: 600px) {
    .editInputContainer {
        margin-top: 15px;
    }

    .fullHeight {
        max-height: 255px;
    }

    .usersWrap .wrapContent {
        max-height: 350px;
    }
}

@media(max-width: 860px) {
    .usersStyle {
        width: 100%;
    }
}

@media(max-height: 400px) {
    .usersWrap .wrapContent {
        min-height: 150px;
    }
}
</style>