<template>
    <div class="ResponsiveContentBoard">
        <div class="titleContainer">
            <img class="titleBackgound" src="@/assets/icons/titleBackgound.svg" />
            <div class="titleText textNormal">
                Perfil atual
            </div>
        </div>

        <div class="wrapLoader" v-if="editProfileLoading">
            <span class="loader" />
            <span class="loaderTxt">
                Carregando...
            </span>
        </div>

        <div class="wrapEdit" v-else>
            <EditProfileInfo :customClass="wrapEditColumn" />
            <CustomSearchList mode="relatedUsers" customClass="wrapEditColumn" />
            <CustomSearchList mode="relatedPlants" customClass="wrapEditColumn" />
        </div>
        <div class="fakeDiv"></div>
    </div>
</template>

<script>
import EditProfileInfo from './EditProfileInfo.vue';
import CustomSearchList from './CustomSearchList.vue';
import MapProfileControl from '@/sys/Control/MapProfiles/MapProfilesControl';

export default {
    name: 'EditProfile',
    components: { EditProfileInfo, CustomSearchList },
    data() {
        return {
            wrapEditColumn: {
                width: 'var(--column-width)',
                height: '100%',
                padding: '7px',
            },
            editProfileLoading: MapProfileControl.currentProfile === undefined
        }
    },
}
</script>

<style>
:root {
    --column-width: 32%;
}

.ResponsiveContentBoard {
    align-content: flex-start;
    padding: 0 5px;
}

.titleContainer {
    position: relative;
    margin-bottom: 5px;
    margin-top: 6px;
    width: 40%;
    float: left;
}

.titleBackgound {
    width: 700px;
}

.titleText {
    position: absolute;
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.wrapEdit {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #36393F;
    width: 100%;
    height: 87%;
    border-radius: 6px;
    padding: 5px;
}

.wrapEditColumn {
    width: 32%;
    height: 100%;
}

.loader {
    width: 58px;
    height: 58px;
    border: 5px solid #1E2227;
    border-bottom-color: #4375EF;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.wrapLoader {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.loaderTxt {
    font-family: Arial, Helvetica, sans-serif;
    color: rgba(255, 255, 255, .7);
    font-size: 18px;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media(max-width: 1450px) {
    .titleBackgound {
        width: 600px;
    }

    .titleText {
        width: 90%;
    }
}


@media(max-width: 1000px) {
    .titleContainer {
        width: 100%;
    }

    .titleBackgound {
        width: 100%;
    }

    .fakeDiv{
        width: 100%;
        height: 18%;
    }
}

@media(max-width: 600px) {
    .titleContainer {
        width: 100%;
    }

    .titleBackgound {
        width: 100%;
    }

    .titleText {
        width: 90%;
    }

    .wrapEdit {
        flex-direction: column;
    }

    .wrapEdit {
        height: fit-content;
    }

    .wrapEditColumn {
        width: 100%;
    }

    :root {
        --column-width: 100%;
    }
}

@media(max-height: 400px) {
    .wrapEdit {
        min-height: 120%;
    }
}
</style>